
import { useSession } from 'next-auth/client'
import { useUserAuth } from './useUserAuth'

export function useUserClearance()  {

    const [ session, loading ] = useSession()

    const { userAuthData, isUserAuthLoading, isUserAuthError } = useUserAuth()

    var userCleared = (!loading && session)
    var userClearedData = (!isUserAuthLoading && !isUserAuthError) 
                                ? userAuthData 
                                : session?.user?.all
    var userClearedLoading = loading || isUserAuthLoading
    var userClearedError = ((!loading && !session) || isUserAuthError)

    return {
        userCleared: userCleared,
        userClearedData: userClearedData,
        userClearedLoading: userClearedLoading,
        userClearedError: userClearedError 
    }

}