import LayoutPageBlank from '@/components/templateux/layout/layout-page-blank'
import { meta } from '@/lib/config';
import React from "react";
import LoadingInner from "./loading-inner";

export default function Loading ({
    message=<>Loading Page</>
  , showRandom=false

}) {

  return (
    <>
      <div className="flex  ">
          <div className="m-auto">
            <h3 className="text-lg sm:text-4xl">
            <LoadingInner
              showRandom={showRandom}
              message={message}
              sizeClass='w-32 h-32 sm:w-48 sm:h-48'
            />
        </h3>
      </div>
    </div>
    </>)

  
}