import Loading from '@/components/templateux/loading/loading'
import { session, signIn, useSession } from "next-auth/client"

export default function DataStatus ({
                           dataClearedLoading
                          ,dataCleared
                          ,dataClearedError
                          ,dataBypass = false
                        }) {

 

  

  if (!dataBypass && dataClearedLoading && !typeof window == undefined) {
    return (<><Loading 
                showRandom={true}
              /></>)
  } 

  if (dataBypass && !dataCleared) {//blank form (eg. create new)
    signIn()
    return (<>
           <div className='font-bold mt-5'>
                Your account is not authorized to view this page...
              </div> 
          </>)
  } 


  if (!dataBypass && !dataClearedLoading && !dataCleared) {// data filled (eg. edit or page access)

    signIn()
    return (<>
            <div className='font-bold mt-5'>
                Your account is not authorized to view this page...
              </div> 
          </>)
  } 

  if (dataClearedError) {
    return (<>Something went wrong...</>)
  }

  return (<></>)
  
}