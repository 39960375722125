
import { useSWRWrapper } from '@/lib/utils/hooks/swr-hooks'
import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

import { useUserClearance } from '@/lib/utils/hooks/useUserClearance'
import DataStatus from '@/components/auth/unauthenticated/data-status'
import { config, meta, navtype_info } from '@/lib/config'
import { useUserAuth } from '@/lib/utils/hooks/useUserAuth'

import Footer from '@/components/templateux/footer'
import NavPrimary from '@/components/templateux/nav/nav-primary'
import LayoutPageBlank from '@/components/templateux/layout/layout-page-blank'



export default function Dashboard(props) {

          //PAGE VARIABLES
        const menucategory = 'user_name'
  const navtype = 'dashboard'
  const navtitle = navtype_info[navtype].title
  const pagetitle = `${navtitle}`

 
  
  //HOOKS ABOVE SECURITY CALLS
  const router = useRouter()
  var user_name = router.query.user_name?.toString()


  const [totalitems, set_totalitems] = useState(0);
  const storagekeytour = 'dashboard-showtour'
  const [showtour, set_showtour] = useState( "1"); //use local storage
  const storagekeydrafts = 'dashboard-showdrafts'
  const [showdrafts, set_showdrafts] = useState(("0")); //use local storage
  const storagekeychecklist = 'dashboard-showchecklist'
  const [showchecklist, set_showchecklist] = useState("1"); //use local storage
  const storagekeyuseractivity = 'dashboard-showuseractivity'
  const [showuseractivity, set_showuseractivity] = useState( "0"); //use local storage
  const storagekeynews = 'dashboard-shownews'
  const [shownews, set_shownews] = useState( "1"); //use local storage
  const [checklistarray, set_checklistarray] = useState([]) 
  const date_diff = 14 //how many days should the second sql date start from
  const sql_interval = 'DAY' //used by sql for date range

  const { userAuthData, isUserAuthLoading, isUserAuthError } = useUserAuth()
  const username = userAuthData?.user_name || ""

  //KEEP HOOKS ABOVE SECURITY CALLS

  // const {
  //   useractivityarraydata
  // , useractivityarraydataisLoading
  // , useractivityarraydataisError
  // } = useuserActivities(1);

 //USER ACTIVITY DATA
  const { 
    data: useractivityarraydata
  , loading: useractivityarraydataisLoading
  , error: useractivityarraydataisError
                  } = useSWRWrapper(
                    '0' //id
                    ,`/api/private/user-activity/select-by-user?page=${1}&sort=${config.itemsort}&dir=${config.itemdir}&q=${''}` //key
                    ,true //retry?
                    ,config.max_retries //max number of retries
                    ,config.retry_interval //retry interval
                    ,0 //refreshinterval (0=none)
                    )

  // const {
  //   user_checklistdata
  // , user_checklistdataisLoading
  // , user_checklistdataisError
  // } = useUserChecklist();

      //USER SETTINGS DATA
      const { 
        data: user_checklistdata
      , loading: user_checklistdataisLoading
      , error: user_checklistdataisError
                      } = useSWRWrapper(
                        '0' //id
                        ,`/api/private/help-checklist/select-by-user` //key
                        ,true //retry?
                        ,config.max_retries //max number of retries
                        ,config.retry_interval //retry interval
                        ,0 //refreshinterval (0=none)
                        )

  
  useEffect(() => {
    if (!user_checklistdataisLoading && !user_checklistdataisError) {
      set_checklistarray([...user_checklistdata[0]])
    }
  }, [user_checklistdata
    , user_checklistdataisLoading
    , user_checklistdataisError]);

  //SECURITY CALL
  const { 
          userCleared
        , userClearedData
        , userClearedLoading
        , userClearedError
       } = useUserClearance()

  useEffect(() => {
  if (userCleared) {
    // set_totalitems(
    //   calcItemDisplay(userClearedData["sum_broadcasts"], config.itemsperpage, 1)
    // );
  }
}, [userCleared]);



  if (userClearedLoading || !userCleared) {

  return (<><DataStatus
              dataClearedLoading={userClearedLoading}
              dataCleared={userCleared} 
              dataClearedError={userClearedError}
            /></>)

  }
  // END SECURITY CALL


  


  return (
    <LayoutPageBlank
          title={`Dashboard`}
          url={`${process.env.NEXT_PUBLIC_SERVER_WEBSITE}/${router.pathname}`}
          image={meta.image}
      >


        <NavPrimary
            user_name={userAuthData?.user_name}
            userAuthData={userClearedData}
            isUserAuthLoading={isUserAuthLoading}
            isUserAuthError={isUserAuthError}
            navtype={navtype}
          />

      {/* //DASHBOARD MENU */}
      {/* 2xl:flex-row */}
      <div className="w-full flex  flex-col-reverse">
        
        {/* //SIDEBAR 2 */}
        {/* 2xl:border-r-2 2xl:w-96  */}
        <div
          className=" w-full flex-0 border-white"
        >
            {/* //CHECKLIST */}
            {/* <SectionDividerCollapse 
              itemstate={showchecklist}
              set_itemstate={set_showchecklist}
              storagekey={storagekeychecklist}
              headline={`Onboarding Checklist`}
              barextra=''
              link_href=''
              buttontext=''
              buttonicon=''
            >
            <BannerWrapper>
            <CheckList
              set_showchecklist={set_showchecklist}
              showchecklist={showchecklist}
              user_checklistdata={user_checklistdata}
              user_checklistdataisLoading={user_checklistdataisLoading}
              user_checklistdataisError={user_checklistdataisError}
              checklistarray={checklistarray}
              set_checklistarray={set_checklistarray}
              user_name={user_name}
            />
            </BannerWrapper>
          </SectionDividerCollapse> */}

          
          {/* //PRODUCT NEWS */}
          {/* <SectionDividerCollapse 
              itemstate={shownews}
              set_itemstate={set_shownews}
              storagekey={storagekeynews}
              headline={`Announcements`}
              barextra=''
              link_href={`${locale()}/news`}
              buttontext={`See All`}
              buttonicon=''
            >
           
            <BannerWrapper>
              <ShowNews 
                shownews={shownews}
                set_shownews={set_shownews}
              />
            </BannerWrapper>
           
          </SectionDividerCollapse> */}

          

          

        </div>

        {/* CONTENT */} 
        {/* 2xl:border-l-2 */}
        <div className="flex-1  border-gray-200"> 

            {/* DRAFTS */}
            {/* <SectionDividerCollapse 
                itemstate={showdrafts}
                set_itemstate={set_showdrafts}
                storagekey={storagekeydrafts}
                headline={`Drafts (${localstoragedrafts?.length})`}
                barextra=''
                link_href=''
                buttontext=''
                buttonicon=''
              >
               <BannerWrapper>
                <ShowDrafts 
                  user_name={user_name}
                  localstoragedrafts={localstoragedrafts}
                />
               </BannerWrapper>
              </SectionDividerCollapse> */}


            {/* PRODUCT TOUR */}
            {/* <SectionDividerCollapse 
              itemstate={showtour}
              set_itemstate={set_showtour}
              storagekey={storagekeytour}
              headline={`Product Tour`}
              barextra=''
              link_href=''
              buttontext=''
              buttonicon=''
            >
             <BannerWrapper>
              <ProductTour 
                username={username}
               />
             </BannerWrapper>
            </SectionDividerCollapse> */}

            
          {/* user ACTIVITIY */}
          {/* <SectionDividerCollapse 
              itemstate={showuseractivity}
              set_itemstate={set_showuseractivity}
              storagekey={storagekeyuseractivity}
              headline="Recent Channel Activity"
              barextra=''
              link_href={`/app/settings/collaborators`}
              buttontext={`Manage Collaborators`}
              buttonicon=''
            >
            <BannerWrapper>
            <UserActivity 
              useractivityarraydata={useractivityarraydata}
              useractivityarraydataisLoading={useractivityarraydataisLoading}
              useractivityarraydataisError={useractivityarraydataisError}
              user_name={user_name}
            />
            </BannerWrapper>
          </SectionDividerCollapse> */}
          
           

            



        </div>
</div>
            <Footer 
          />
    </LayoutPageBlank>
  )


}













